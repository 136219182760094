import { Observable } from 'rxjs';
import { Operations } from 'state-domains/domain/subscription';
import { buildConfigurationCategoriesOperationUrl } from 'state-domains/network';

import { CATEGORY_TYPES, CATEGORY_TYPES_URL_SEGMENT, ConfigurationCategory } from '../types';
import { createOperationObservable } from '../utils';

export const modifyConfigurationCategory = (
    operation: Operations,
    categoryType: CATEGORY_TYPES,
    payload: any = {},
    id = '',
): Observable<{ data: ConfigurationCategory }> => {
    let modifiedType;
    if (categoryType === CATEGORY_TYPES.DISPATCH_HEADER) {
        modifiedType = CATEGORY_TYPES.HEADER;
    } else if (categoryType === CATEGORY_TYPES.VALIDATION_RULE) {
        modifiedType = CATEGORY_TYPES_URL_SEGMENT.VALIDATION_RULE;
    } else {
        modifiedType = categoryType;
    }
    const dataFn = (item: ConfigurationCategory) => ({ ...item, categoryType });
    return createOperationObservable<ConfigurationCategory>(
        operation,
        (id = '') => buildConfigurationCategoriesOperationUrl(modifiedType, id),
        dataFn,
        id,
        payload,
    );
};
